import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";

import ButtonGhost from "../components/Button/ButtonGhost";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Services | Smart Accounting Hub | Small Business Bookkeeping"
            description="No matter what industry you’re in, Smart Accounting Hub's services for small businesses will position you for present and future success. Get a quote today!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <section className="pt-16 md:pt-20 pb-20 md:pb-32">
            <div className="container">
               <header className="mb-16 md:mb-20 max-w-3xl">
                  <h1>Our Services</h1>
                  <p className="text-xl">
                     No matter what industry you’re in, our accounting services will position you for present and future success.
                  </p>
               </header>

               <div className="mb-20 md:mb-32">
                  <div className="grid md:grid-cols-12 gap-y-7 md:gap-x-12 lg:gap-x-18 xl:gap-x-26 items-center">
                     <div className="md:col-start-1 md:col-span-7">
                        <GatsbyImage image={data.personalBookkeeping.childImageSharp.gatsbyImageData} alt="Personal Bookkeeping" />
                     </div>
                     <div className="md:col-end-13 md:col-span-5">
                        <h3>Personal Bookkeeping</h3>
                        <p>
                           For busy business owners and other individuals, our personal bookkeeping services will keep you on track personally so you
                           can thrive professionally.
                        </p>
                        <ButtonGhost href="/personal-bookkeeping-services/" text="Learn More" />
                     </div>
                  </div>
               </div>

               <div className="mb-20 md:mb-32">
                  <div className="grid md:grid-cols-12 gap-y-7 md:gap-x-12 lg:gap-x-18 xl:gap-x-26 items-center">
                     <div className="order-2 md:order-1 md:col-start-1 md:col-span-5">
                        <h3>Business Bookkeeping</h3>
                        <p>
                           We’ll reconcile your revenue, expenses, and bank statements each month and meet with you on your schedule. Get organized so
                           you can be more profitable.
                        </p>
                        <ButtonGhost href="/business-bookkeeping-services/" text="Learn More" />
                     </div>
                     <div className="order-1 md:order-2 md:col-end-13 md:col-span-7">
                        <GatsbyImage image={data.businessBookkeeping.childImageSharp.gatsbyImageData} alt="Business Bookkeeping" />
                     </div>
                  </div>
               </div>

               <div className="mb-20 md:mb-32">
                  <div className="grid md:grid-cols-12 gap-y-7 md:gap-x-12 lg:gap-x-18 xl:gap-x-26 items-center">
                     <div className="md:col-start-1 md:col-span-7">
                        <GatsbyImage image={data.businessManagement.childImageSharp.gatsbyImageData} alt="Business Management" />
                     </div>
                     <div className="md:col-end-13 md:col-span-5">
                        <h3>Business Management</h3>
                        <p>
                           We organize or create new processes to help your business operations be more efficient. Wherever you need assistance, we
                           can fill in the gaps.
                        </p>
                        <ButtonGhost href="/small-business-management-services/" text="Learn More" />
                     </div>
                  </div>
               </div>

               <div>
                  <div className="grid md:grid-cols-12 gap-y-7 md:gap-x-12 lg:gap-x-18 xl:gap-x-26 items-center">
                     <div className="order-2 md:order-1 md:col-start-1 md:col-span-5">
                        <h3>Financial Reporting</h3>
                        <p>
                           Cash flow management is more important than a bank balance or revenue growth. We’ll ensure this is done correctly to keep
                           your business running smoothly.
                        </p>
                        <ButtonGhost href="/financial-reporting-services/" text="Learn More" />
                     </div>
                     <div className="order-1 md:order-2 md:col-end-13 md:col-span-7">
                        <GatsbyImage image={data.financialReporting.childImageSharp.gatsbyImageData} alt="Financial Reporting" />
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <CallToAction />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Home.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Home.jpg" }) {
         publicURL
      }
      personalBookkeeping: file(relativePath: { eq: "home/3.0 Personal Bookkeeping.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      businessBookkeeping: file(relativePath: { eq: "home/4.0 Business bookkeeping.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      businessManagement: file(relativePath: { eq: "home/5.0 Business Managment.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      financialReporting: file(relativePath: { eq: "home/6.0 Financial Reporting.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
   }
`;
export default Page;
