import React from "react";

import ButtonSolid from "../Button/ButtonSolid";
import ButtonGhost from "../Button/ButtonGhost";

const CTA = ({ className, subText }) => {
   return (
      <section className={`bg-secondary py-20 ${className}`}>
         <div className="container">
            <div className="grid md:grid-cols-2 gap-y-10 md:gap-x-10 lg:gap-x-20 items-end justify-between text-center md:text-left">
               <div className="max-w-2xl">
                  <h3 className="heading-two text-white">Let’s take care of business.</h3>
                  <p className="text-white mb-0">
                     {subText || "Call us or request a quote today. We’ll take care of business so you can take care of yours."}
                  </p>
               </div>
               <div className="flex items-center justify-center md:justify-end">
                  <ButtonGhost
                     href="tel:+1-619-292-8205"
                     text={[<i className="fas fa-phone-alt mr-2"></i>, "(619) 292-8205"]}
                     className="mr-4"
                     darkMode={true}
                  />
                  <ButtonSolid as="button" modal="modal-contact" text="Request a Quote" />
               </div>
            </div>
         </div>
      </section>
   );
};

export default CTA;
